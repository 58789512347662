import { useBaseUrlImg } from '@/app/contexts/BaseUrlImgContext'
import React, { useEffect, useRef, useState } from "react"
import CustomLink from "../../CustomLink"
import { ProductContainerProps } from "@/app/interface/components"
import FreemodeSlide from "../../FreemodeSlide"

const buttonData = [
    { name: 'Lançamentos', model: 'news', key: 'v' },
    { name: 'Elétricos', model: 'eletrics', key: 't' },
    { name: 'Híbridos', model: 'hybrids', key: 'u' }
]

const getModels = (model: string, news: any, eletrics: any, hybrids: any) => {
    switch (model) {
        case 'news': return news
        case 'eletrics': return eletrics
        case 'hybrids': return hybrids
        default: return []
    }
}

const ProductContainer: React.FC<ProductContainerProps> = ({ styles, isProductOpen, setIsProductOpen, news, eletrics, hybrids }) => {
    const [activeButton, setActiveButton] = useState<string>(buttonData[0].model)
    const containerRef = useRef<HTMLDivElement>(null)
    const baseUrlImg = useBaseUrlImg()

    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName)
    }

    const renderBadge = (model: any) => {
        return (
            <>
                {model.new && !model.soon && activeButton !== 'news' && <span className={styles.newCar}>Lançamento</span>}
                {model.soon && <span className={styles.soonCar}>Em breve</span>}
                {activeButton === 'news' && model.model === "eletric" && <span className={styles.typeCar}>Elétrico</span>}
                {activeButton === 'news' && model.model === "hybrid" && <span className={styles.typeCar}>Híbrido</span>}
            </>
        )
    }

    const generateLinks = (models: any) => {
        return models?.map((model: any, index: number) => {
            const normalizedModel = model.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ç/g, "c").replace(/\s+/g, "-").replace(".", "-").toLowerCase()
            const imgSrc = `${baseUrlImg}/byd/general/header/cars/` + normalizedModel + '.webp'
            const url = `/${normalizedModel}`

            return (
                <div className={styles.card} key={index}>
                    <CustomLink onClick={() => setIsProductOpen(false)} key={normalizedModel} href={((model.new && model.soon) || normalizedModel === 'tan') ? `/solicitar-proposta/${normalizedModel}` : url} className={styles.models + (((model.new && model.soon) || normalizedModel === 'tan') ? " btn-solicitar-proposta-" + normalizedModel : '')}>
                        <div>
                            <div>
                                {renderBadge(model)}
                            </div>
                            <img src={imgSrc} alt="" width={220} height={220} loading="lazy" />
                        </div>
                        BYD {model.name}
                    </CustomLink>
                    <CustomLink onClick={() => setIsProductOpen(false)} href={`/solicitar-proposta/${normalizedModel}`} className={"btn-primary " + "btn-solicitar-proposta-" + normalizedModel}>Solicitar proposta</CustomLink>
                </div>
            )
        })
    }

    // Focus trapping para o menu de produtos
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isProductOpen || !containerRef.current) return

            const focusableElements = containerRef.current.querySelectorAll<HTMLElement>(
                'a, button, [tabindex]:not([tabindex="-1"])'
            )
            const firstElement = focusableElements[0]
            const lastElement = focusableElements[focusableElements.length - 1]

            if (event.key === "Tab") {
                if (event.shiftKey) {
                    // Shift + Tab: Vai para o elemento anterior
                    if (document.activeElement === firstElement) {
                        event.preventDefault()
                        lastElement.focus()
                    }
                } else {
                    // Tab: Vai para o próximo elemento
                    if (document.activeElement === lastElement) {
                        event.preventDefault()
                        firstElement.focus()
                    }
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [isProductOpen])

    return (
        <div
            ref={containerRef}
            id="product-menu"
            aria-hidden={!isProductOpen}
            tabIndex={isProductOpen ? 0 : -1}
            className={`${styles.products}${isProductOpen ? ' ' + styles.show : ''}`}
        >
            {isProductOpen &&
                <>
                    <FreemodeSlide gap="16px">
                        {buttonData.map(button => (
                            <button
                                type="button"
                                key={button.name}
                                className={`${styles.selectModel} ${activeButton === button.model ? styles.active : ''}`}
                                onClick={() => handleButtonClick(button.model)}
                            >
                                <span className="CMS">{button.key}</span> {button.name}
                            </button>
                        ))}
                    </FreemodeSlide>

                    <FreemodeSlide gap="32px" padding="0px 16px">
                        {generateLinks(getModels(activeButton, news, eletrics, hybrids))}
                    </FreemodeSlide>
                </>
            }
        </div>
    )
}

export default React.memo(ProductContainer)
