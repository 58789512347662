import { useBaseUrlImg } from '@/app/contexts/BaseUrlImgContext'
import React, { useEffect, useRef } from "react"
import CustomLink from "../../CustomLink"
import { ExploreContainerProps } from "@/app/interface/components"
import ImageComponentHeightFixed from "../../ImageComponentHeightFixed"

const ExploreContainer: React.FC<ExploreContainerProps> = ({ styles, isExploreOpen, setIsExploreOpen, setIsMenuOpen }) => {
    const containerRef = useRef<HTMLUListElement>(null)
    
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isExploreOpen || !containerRef.current) return

            const focusableElements = containerRef.current.querySelectorAll<HTMLElement>(
                'a, button, [tabindex]:not([tabindex="-1"])'
            )
            const firstElement = focusableElements[0]
            const lastElement = focusableElements[focusableElements.length - 1]

            if (event.key === "Tab") {
                if (event.shiftKey) {
                    // Shift + Tab: Vai para o elemento anterior
                    if (document.activeElement === firstElement) {
                        event.preventDefault()
                        lastElement.focus()
                    }
                } else {
                    // Tab: Vai para o próximo elemento
                    if (document.activeElement === lastElement) {
                        event.preventDefault()
                        firstElement.focus()
                    }
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [isExploreOpen])
    return (
        <ul 
            ref={containerRef}
            id="explore-menu"
            aria-hidden={!isExploreOpen}
            tabIndex={isExploreOpen ? 0 : -1} 
            className={`${styles.explore}${isExploreOpen ? ' ' + styles.show : ''}`}
        >
            {isExploreOpen &&
                <>
                    <li>
                        <CustomLink href="/sobre" onClick={() => {
                            setIsExploreOpen(false)
                            setIsMenuOpen?.(false)
                        }}>
                            <span>Sobre a BYD</span>
                            <div>
                                <ImageComponentHeightFixed src={`${useBaseUrlImg()}/byd/general/header/about.webp`} height={132} loading="lazy" alt="" />
                            </div>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink href="/consorcio" onClick={() => {
                            setIsExploreOpen(false)
                            setIsMenuOpen?.(false)
                        }}>
                            <span>Consórcio BYD</span>
                            <div>
                                <ImageComponentHeightFixed src={`${useBaseUrlImg()}/byd/general/header/consorcio.webp`} height={132} loading="lazy" alt="" />
                            </div>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink href="/pos-venda" onClick={() => {
                            setIsExploreOpen(false)
                            setIsMenuOpen?.(false)
                        }}>
                            <span>Pós-Venda BYD</span>
                            <div>
                                <ImageComponentHeightFixed src={`${useBaseUrlImg()}/byd/general/header/pos-venda.webp`} height={132} loading="lazy" alt="" />
                            </div>
                        </CustomLink>
                    </li>
                    <li>
                        <CustomLink href="/venda-direta" onClick={() => {
                            setIsExploreOpen(false)
                            setIsMenuOpen?.(false)
                        }}>
                            <span>Venda Direta BYD</span>
                            <div>
                                <ImageComponentHeightFixed src={`${useBaseUrlImg()}/byd/general/header/venda-direta.webp`} height={132} loading="lazy" alt="" />
                            </div>
                        </CustomLink>
                    </li>
                    {/*<li>
                        <CustomLink href="/blog" onClick={() => {
                        setIsExploreOpen(false)
                        setIsMenuOpen?.(false)
                        }}>
                            <span>BLOG</span>
                                <div>
                                    <ImageComponentHeightFixed src=`${useBaseUrlImg()}/byd/general/blog.webp" height={132} loading="lazy" alt="" />
                                </div>
                        </CustomLink>
                    </li>*/}
                    <li>
                        <CustomLink target="_blank" href="https://www.seminovosvitoriamotorsbyd.com.br" onClick={() => {
                            setIsExploreOpen(false)
                            setIsMenuOpen?.(false)
                        }}>
                            <span>Seminovos</span>
                            <div>
                                <ImageComponentHeightFixed src={`${useBaseUrlImg()}/byd/general/header/seminovos.webp`} height={132} loading="lazy" alt="" />
                            </div>
                        </CustomLink>
                    </li>
                </>
            }
        </ul>
    )
}

export default React.memo(ExploreContainer)