import { useBaseUrlImg } from '@/app/contexts/BaseUrlImgContext'
import { VehicleSectionProps } from "@/app/interface/components"
import React from "react"
import CustomLink from "@/app/components/UI/CustomLink"
import styles from '@/styles/desktop/Home/VehicleSection.module.scss'
import ImageComponentHeightFixed from "@/app/components/UI/ImageComponentHeightFixed"

const VehicleSection: React.FC<VehicleSectionProps> = ({ justRequestBtn=false, lancamento=false, newModel=false, title, content, car }) => {
    return (
        <section className={styles.main} role="region" aria-labelledby="vehicle-title">
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    {newModel && (
                        <span tabIndex={0}
                         className="newModel">
                            Novo
                        </span>
                    )}
                    
                    {lancamento && (
                        <span tabIndex={0}
                         className="newModel">
                            Lançamento
                        </span>
                    )}

                    <div className={styles.title}>
                        <h2 tabIndex={0}
                         id="vehicle-title" className="h2-large">{title}</h2>
                        <p tabIndex={0}>{content}</p
                        >
                    </div>
                </div>
                <div className={styles.links}>
                    {!justRequestBtn && (
                        <>
                            <CustomLink
                                className="btn-tertiary"
                                href={'/' + (car === 'tan' ? 'tan-2025' : car)}
                                aria-label={`Saiba mais sobre o modelo ${car}`}>
                                    Saiba mais
                            </CustomLink>
                            <CustomLink
                                className={"btn-tertiary " + "btn-test-drive-" + car}
                                href={'/test-drive/' + car}
                                aria-label={`Solicitar test drive para o modelo ${car}`}>
                                    Test drive
                            </CustomLink>
                        </>
                    )}
                    <CustomLink
                        className={"btn-tertiary " + "btn-solicitar-proposta-" + car}
                        href={'/solicitar-proposta/' + car}
                        aria-label={`Solicitar proposta para o modelo ${car}`}>
                            Solicitar proposta
                    </CustomLink>
                </div>
            </div>
            <ImageComponentHeightFixed
                src={useBaseUrlImg() + '/byd/desktop/home/' + car + '.webp'}
                alt=""
                height={800}
                loading={newModel ? 'eager' : 'lazy'}
            />
        </section>
    )
}

export default React.memo(VehicleSection)
