import { openDB, IDBPDatabase } from 'idb' // Biblioteca para facilitar o uso do IndexedDB
import { Vehicle } from '../interface'
import axios from 'axios'
import memoryCache from './cache'

// Configuração do IndexedDB
const DB_NAME = 'VehiclesDB'
const STORE_NAME = 'vehicles'
const DB_VERSION = 1 // Incrementa a versão para garantir que o upgrade seja executado
const baseUrl = `${
    typeof window !== 'undefined'
        ? window.location.origin
        : process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000'
}/api/proxy`

// Inicializar o IndexedDB
const initDB = async (): Promise<IDBPDatabase> => {
    const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true })
            }
        },
    })
    return db
}

// Recriar o banco de dados caso o object store esteja ausente
const recreateDB = async (): Promise<void> => {
    await indexedDB.deleteDatabase(DB_NAME)
    await initDB()
}

// Salvar os dados no IndexedDB
const saveToIndexedDB = async (data: Vehicle[]): Promise<void> => {
    const db = await initDB()
    const tx = db.transaction(STORE_NAME, 'readwrite')
    const store = tx.objectStore(STORE_NAME)

    // Limpa os dados antigos
    await store.clear()

    // Adiciona os novos dados
    for (const item of data) {
        await store.add(item)
    }

    await tx.done
}

// Buscar os dados do IndexedDB
export const getFromIndexedDB = async (): Promise<Vehicle[]> => {
    try {
        const db = await initDB()

        // Verifica se o object store existe
        if (!db.objectStoreNames.contains(STORE_NAME)) {
            console.warn(`Object store "${STORE_NAME}" não encontrado. Recriando banco de dados...`)
            await recreateDB()
            return []
        }

        const tx = db.transaction(STORE_NAME, 'readonly')
        const store = tx.objectStore(STORE_NAME)
        const allData = await store.getAll()

        return (allData || []) as Vehicle[]
    } catch (error) {
        console.error('Erro ao buscar dados do IndexedDB:', error)
        return []
    }
}

// Verificar o status de atualização
export const isUpdated = async () => {
    let status = memoryCache.get<string>('status') || null
    const genStatus = crypto.randomUUID()

    return { status, genStatus }
}

// Função para buscar dados da API e salvar no IndexedDB
export const fetchVehicles = async (storedStatus: string, dataStatus: string | undefined, genStatus: string) => {
    try {
        const cachedData = await getFromIndexedDB()

        // Se os dados não estão atualizados ou o cache está vazio, busca da API
        if (storedStatus !== dataStatus || !cachedData.length) {
            const response = await axios.get<Vehicle[]>(`${baseUrl}?endpoint=vehicles`)

            await saveToIndexedDB(response.data)

            if(!dataStatus) {
                await fetch('/api/updateCacheStatus', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        status: genStatus,
                    }),
                })
            }

            return response.data
        }

        // Retorna os dados do cache se estiverem disponíveis
        return cachedData
    } catch (error) {
        console.error('Erro ao buscar veículos:', error)

        // Tenta retornar o cache mesmo em caso de erro na API
        const fallbackData = await getFromIndexedDB()
        return fallbackData.length > 0 ? fallbackData : []
    }
}
