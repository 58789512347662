import React, { createContext, useContext } from 'react'

type BaseUrlImgContextProps = {
  baseUrlImg: string
}

const BaseUrlImgContext = createContext<BaseUrlImgContextProps | undefined>(undefined)

export const BaseUrlImgProvider: React.FC<{ baseUrlImg: string, children: React.ReactNode }> = ({ baseUrlImg, children }) => {
  return (
    <BaseUrlImgContext.Provider value={{ baseUrlImg }}>
      {children}
    </BaseUrlImgContext.Provider>
  )
}

export const useBaseUrlImg = (): string => {
  const context = useContext(BaseUrlImgContext)
  if (!context) {
    throw new Error('useBaseUrlImg must be used within a BaseUrlImgProvider')
  }
  return context.baseUrlImg
}
