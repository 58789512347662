import { useBaseUrlImg } from '@/app/contexts/BaseUrlImgContext'
import React from "react"
import styles from "@/styles/mobile/Footer.module.scss"
import CustomLink from "../CustomLink"

const FooterMobile: React.FC<any> = ({ contents, defenseTypes }) => {
    return (
        <footer className={styles.main}>
            <span tabIndex={0} className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-motors</span>

            <div className={styles.icons}>
                <CustomLink href="https://www.facebook.com/people/BYD-Vit%C3%B3ria-Motors/100083028827686/" className="CMS" aria-label="Facebook" title="Facebook">w</CustomLink>
                <CustomLink href="https://www.instagram.com/vitoriamotorsbyd/?igshid=YmMyMTA2M2Y%3D" className="CMS" aria-label="Instagram" title="Instagram">x</CustomLink>
                <CustomLink href="https://www.linkedin.com/company/vit%C3%B3ria-motors-byd/?originalSubdomain=br" className="CMS" aria-label="Linkedin" title="Linkedin">y</CustomLink>
                <CustomLink href="https://www.youtube.com/channel/UChM6s5BNanp9Q0DH6GnXsPw" className="CMS" aria-label="Youtube" title="Youtube">z</CustomLink>
            </div>

            <div className={styles.ibama}>
                <img width={73} height={83} src={`${useBaseUrlImg()}/byd/general/ibama.svg`} alt="" />
                <span tabIndex={0}>Desacelere. Seu bem maior é a vida.</span>
            </div>

            <div className={styles.text}>
                <p tabIndex={0}>As características técnicas, especificações, itens de série ou opcionais, bem como acabamentos, cores e características mecânicas e de desempenho estão sujeitos a alterações sem aviso prévio pela BYD Brasil. Algumas configurações descritas neste site podem ser alteradas durante a produção dos veículos BYD. Consulte a equipe de vendas sobre a disponibilidade das características do veículo escolhido.</p>
                <p tabIndex={0}>* A autonomia total pode variar dependendo dos hábitos de direção e carregamento, velocidade, condições de tempo e temperatura e o tempo de uso da bateria. A capacidade da bateria é a capacidade inicial, ela pode diminuir com o tempo. O tempo de uso e a temperatura do ambiente interferem no tempo de carregamento.</p>
            </div>

            <div className={styles.externalLinks}>
                <div>
                    <CustomLink href="https://privacidadecomercio.aguiabranca.com.br" target="_blank">Política de privacidade</CustomLink>
                    <CustomLink href="https://compliancedivisaocomercio.com.br" target="_blank">Canal de denúncia</CustomLink>
                </div>
                <CustomLink className="LogoGAB" href="https://www.gab.com.br" target="_blank" aria-label="Grupo Águia Branca" title="Grupo Águia Branca">ABCD</CustomLink>
            </div>

            <div className={styles.copyright}>
                <span tabIndex={0}>©2024 Vitória Motors BYD.</span>
                <span tabIndex={0}>Todos os direitos reservados.</span>
            </div>

            {(defenseTypes?.includes("defesa ao fim da página") || defenseTypes?.includes("defesa no botão condições e ao fim da página")) && (
                <div className="defesas">
                    {contents?.map((content: string, index: number) => (
                        (defenseTypes[index] === "defesa ao fim da página" || defenseTypes[index] === "defesa no botão condições e ao fim da página") && (
                            <p tabIndex={0} key={index} dangerouslySetInnerHTML={{ 
                                __html: content
                            }} />
                        )
                    ))}
                </div>
            )}
        </footer>
    )
}

export default React.memo(FooterMobile)