import { useBaseUrlImg } from '@/app/contexts/BaseUrlImgContext'
import React from 'react'
import styles from '@/styles/Chatbot.module.scss'
import { IChatMessage } from '@/app/interface/IChatMessage'
import { MdWhatsapp } from "react-icons/md"
import ChatSelect from './ChatSelect'

interface ChatMessagesProps {
  conversation: IChatMessage[]
  finalMessageRef: React.RefObject<HTMLDivElement>
  handleWhatsAppClick: () => void
  handleCheckboxChange: (e: any) => void
  userReply: (input: string) => void
  step: number
  options: any
  team: string
  teamEdit: string
  carOptions: any
  chosenOptions: any
  unit: string
  unitEdit: string
  model: string
  modelEdit: string
  field: string
  handleTeamChange: any
  handleUnitChange: any
  handleModelChange: any
  unitOptions: any
}

const ChatMessages: React.FC<ChatMessagesProps> = ({
  conversation,
  finalMessageRef,
  handleWhatsAppClick,
  handleCheckboxChange,
  userReply,
  step,
  options,
  team,
  teamEdit,
  carOptions,
  chosenOptions,
  unit,
  unitEdit,
  model,
  modelEdit,
  field,
  handleTeamChange,
  handleUnitChange,
  handleModelChange,
  unitOptions
}) => {

  return (
    <div className={styles.conversation}>
      <div className={styles.conversationContent}>
        {conversation.map((msg, index) => (
          <div key={index} ref={index === conversation.length - 1 ? finalMessageRef : null} className={`${styles.message} ${styles[msg.sender]}`}>
            {msg.firstMsgBot && (
              <div className={styles.iconChatbot} style={msg.sender === 'user' ? { display: 'none' } : {}}>
                <img src={useBaseUrlImg() + '/byd/general/gabi.webp'} width={40} height={40} alt="" className={styles.icon} />
              </div>
            )}
            {!msg.isCode && !msg.confirmBtn && msg.text && (
              <p tabIndex={0} className={msg.firstMsgBot ? styles.vector : ''} style={!msg.firstMsgBot ? { marginLeft: '48px' } : {}} dangerouslySetInnerHTML={{ __html: msg.text }}></p>
            )}
            {msg.confirmBtn && (
              <div className={styles.confirmBtn}>
                <button id="submitChatbot" onClick={() => userReply('sim')} className={styles.btnConfirm}>sim</button>
                <button onClick={() => userReply('não')} className={styles.btnConfirm}>não</button>
              </div>
            )}
            {msg.isCode && (
              <div className={styles.wppBtn}>
                <button onClick={handleWhatsAppClick} className={styles.whatsAppButton}><MdWhatsapp size={20} />Falar agora</button>
              </div>
            )}
            {msg.isEdit && (
              <div className={styles.optionsEdit}>
                <div className={styles.checkbox}>
                  <label>
                    <input type="checkbox" id="fullName" name="fullName" value="Nome" onChange={handleCheckboxChange} />
                    Nome
                  </label>
                  <label>
                    <input type="checkbox" id="email" name="email" value="E-mail" onChange={handleCheckboxChange} />
                    E-mail
                  </label>
                  <label>
                    <input type="checkbox" id="cpf" name="cpf" value="CPF" onChange={handleCheckboxChange} />
                    CPF
                  </label>
                  <label>
                    <input type="checkbox" id="phone" name="phone" value="Telefone" onChange={handleCheckboxChange} />
                    Telefone
                  </label>
                  <label>
                    <input type="checkbox" id="team" name="team" value="Equipe" onChange={handleCheckboxChange} />
                    Equipe
                  </label>
                  <label>
                    <input type="checkbox" id="unit" name="unit" value="Unidade" onChange={handleCheckboxChange} />
                    Unidade
                  </label>
                  <label>
                    <input type="checkbox" id="model" name="model" value="Modelo" onChange={handleCheckboxChange} />
                    Modelo
                  </label>
                  <label>
                    <input type="checkbox" id="message" name="message" value="Assunto" onChange={handleCheckboxChange} />
                    Assunto
                  </label>
                </div>
                <button disabled={!chosenOptions.length} onClick={() => userReply(chosenOptions.join(', '))}>Confirmar</button>
              </div>
            )}
          </div>
        ))}
        {step === 5 && (
          <ChatSelect 
            name='team'
            value={team}
            onChange={handleTeamChange}
            options={options}
            placeholder='Selecione uma equipe'
          />
        )} 
        {step === 6 && (
          <ChatSelect 
            name='unit'
            value={unit}
            onChange={handleUnitChange}
            options={unitOptions.unit}
            placeholder='Selecione uma unidade'
          />
        )} 
        {step === 7 && (
          <ChatSelect 
            name='model'
            value={model}
            onChange={handleModelChange}
            options={carOptions}
            placeholder='Selecione um modelo'
          />
        )}
        {field === 'Equipe' && (
          <ChatSelect 
            name='team'
            value={teamEdit}
            onChange={handleTeamChange}
            options={options}
            placeholder='Selecione uma equipe'
          />
        )} 
        {field === 'Unidade' && (
          <ChatSelect 
            name='unit'
            value={unitEdit}
            onChange={handleUnitChange}
            options={unitOptions.unit}
            placeholder='Selecione uma unidade'
          />
        )}
        {field === 'Modelo' && (
          <ChatSelect 
            name='model'
            value={modelEdit}
            onChange={handleModelChange}
            options={carOptions}
            placeholder='Selecione um modelo'
          />
        )}
      </div>
    </div>
  )
}

export default ChatMessages
