import { useEffect, useState } from 'react'
import { fetchVehicles } from '../libs/getVehiclesBYD'

function useFetchAndCacheVehicles(dataStatus: string | undefined, genStatus: string): boolean {
    const [isFetchComplete, setFetchComplete] = useState(false)

    useEffect(() => {
        const storedStatus = localStorage.getItem('dataStatus') || ''

        async function fetchAndCacheVehicles() {
            await fetchVehicles(storedStatus, dataStatus, genStatus)
            const status = dataStatus ? dataStatus : genStatus
            if(dataStatus !== storedStatus) localStorage.setItem('dataStatus', status)
            setFetchComplete(true)
        }

        fetchAndCacheVehicles()
    }, [])

    return isFetchComplete
}

export default useFetchAndCacheVehicles
