import React, { useEffect, useRef, useState } from "react"
import styles from "@/styles/mobile/Header.module.scss"
import { useTheme } from "@/app/contexts/ThemeContext"
import ExploreContainer from "./ExploreContainer"
import ProductContainer from "./ProductContainer"
import { HeaderProps } from "@/app/interface/components"
import CustomLink from "../../CustomLink"
import { useRouter } from "next/router"

const HeaderMobile: React.FC<HeaderProps> = ({ setIsAlertModalOpen, vehicles }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const exploreButtonRef = useRef<HTMLButtonElement>(null)
    const productButtonRef = useRef<HTMLButtonElement>(null)
    const menuButtonRef = useRef<HTMLButtonElement>(null)
    const [isExploreOpen, setIsExploreOpen] = useState<boolean>(false)
    const [isProductOpen, setIsProductOpen] = useState<boolean>(false)
    const { theme, toggleTheme } = useTheme()
    const menuRef = useRef<HTMLDivElement>(null)
    const router = useRouter()
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.body.className = theme
    }, [theme])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false)
                setIsExploreOpen(false)
                setIsProductOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
    }, [isMenuOpen])

    const altMode = theme === "light" ? "Ativar tema escuro" : "Ativar tema claro"

    const handleMenuClick = (menu: "explore" | "product") => {
        if (menu === "explore") {
            if (isProductOpen) {
                setIsProductOpen(false)
                setTimeout(() => {
                    setIsExploreOpen(true)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsExploreOpen(!isExploreOpen)
            }
        }

        if (menu === "product") {
            if (isExploreOpen) {
                setIsExploreOpen(false)
                setTimeout(() => {
                    setIsProductOpen(true)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsProductOpen(!isProductOpen)
            }
        }
    }

    // Fechar menu com tecla "Esc"
        useEffect(() => {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Escape") {
                    if (isExploreOpen) {
                        setIsExploreOpen(false)
                        exploreButtonRef.current?.focus()
                    }
                    if (isProductOpen) {
                        setIsProductOpen(false)
                        productButtonRef.current?.focus()
                    }
                    if (isMenuOpen && !isExploreOpen && !isProductOpen) {
                        setIsMenuOpen(false)
                        menuButtonRef.current?.focus()
                    }
                }
            }
    
            document.addEventListener("keydown", handleKeyDown)
    
            return () => {
                document.removeEventListener("keydown", handleKeyDown)
            }
        }, [isExploreOpen, isProductOpen])

    useEffect(() => {
        if (isExploreOpen) {
            (document.querySelector(`.${styles.explore}`) as HTMLElement)?.focus()
        } else if (isProductOpen) {
            (document.querySelector(`.${styles.products}`) as HTMLElement)?.focus()
        } else if (isMenuOpen) {
            (document.querySelector(`.${styles.menuOpen}`) as HTMLElement)?.focus()
        }
    }, [isExploreOpen, isProductOpen])

    // Focus trapping para o menu
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isMenuOpen || !containerRef.current) return

            const focusableElements = containerRef.current.querySelectorAll<HTMLElement>(
                'a, button, [tabindex]:not([tabindex="-1"])'
            )
            const firstElement = focusableElements[0]
            const lastElement = focusableElements[focusableElements.length - 1]

            if (event.key === "Tab") {
                if (event.shiftKey) {
                    // Shift + Tab: Vai para o elemento anterior
                    if (document.activeElement === firstElement) {
                        event.preventDefault()
                        lastElement.focus()
                    }
                } else {
                    // Tab: Vai para o próximo elemento
                    if (document.activeElement === lastElement) {
                        event.preventDefault()
                        firstElement.focus()
                    }
                }
            }
        }

        document.addEventListener("keydown", handleKeyDown)

        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [isMenuOpen])

    return (
        <header className={styles.header}>
            <div className={styles.main} ref={menuRef}>
                <div className={styles.menu}>
                    <CustomLink href="/" onClick={() => setIsMenuOpen(false)}>
                        {router.pathname === "/" ? (
                            <h1 className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-Motors</h1>
                        ) : (
                            <span className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-Motors</span>
                        )}
                    </CustomLink>
                    <div className={styles.content}>
                        <button
                            type="button"
                            className={styles.mode + " CMS"}
                            onClick={toggleTheme}
                            aria-label={altMode}
                            title={altMode}
                        >
                            {theme === "light" ? "r" : "s"}
                        </button>
                        <button ref={menuButtonRef} style={{textTransform: "lowercase"}} type="button" onClick={() => setIsMenuOpen(!isMenuOpen)} className="CMS" aria-expanded={isMenuOpen} aria-controls="menu">
                            {!isMenuOpen ? "a" : "b"}
                        </button>
                    </div>
                </div>

                <nav 
                    id="menu"
                    aria-hidden={!isMenuOpen}
                    tabIndex={isMenuOpen ? 0 : -1}
                    ref={containerRef} 
                    className={`${styles.menuOpen}${isMenuOpen ? ' ' + styles.show : ''}`}
                >
                    {isMenuOpen && 
                        <>
                            <button type="button" onClick={() => setIsAlertModalOpen(true)}>Alerta ao consumidor <span className="CMS">M</span></button>

                            <button type="button" onClick={() => handleMenuClick("explore")} ref={exploreButtonRef} aria-expanded={isExploreOpen} aria-controls="explore-menu">Explorar <span className={`CMS${isExploreOpen ? ' rotate' : ''}`}>b</span></button>
                            <ExploreContainer setIsMenuOpen={setIsMenuOpen} setIsExploreOpen={setIsExploreOpen} styles={styles} isExploreOpen={isExploreOpen} />

                            <button type="button" onClick={() => handleMenuClick("product")} ref={productButtonRef} aria-expanded={isProductOpen} aria-controls="product-menu">Produtos <span className={`CMS${isProductOpen ? ' rotate' : ''}`}>b</span></button>
                            <ProductContainer setIsProductOpen={setIsProductOpen} styles={styles} isProductOpen={isProductOpen} news={vehicles.news} eletrics={vehicles.eletrics} hybrids={vehicles.hybrids} setIsMenuOpen={setIsMenuOpen} />
                            <ul className={`${isProductOpen ? styles.contactOpen : styles.contact}`}>
                                <li>
                                    <CustomLink onClick={() => setIsMenuOpen(!isMenuOpen)} className="btn-confirm btn-solicitar-proposta" href="/solicitar-proposta">Solicitar Proposta</CustomLink>
                                </li>
                            </ul>
                        </>
                    }
                </nav>
            </div>
        </header>
    )
}

export default React.memo(HeaderMobile)
