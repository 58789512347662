import Main from '@/app/components/Home'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import useUpdateHead from '@/app/hooks/useUpdateHead'
import { HomeProps } from '@/app/interface/pages'
import { fetchBanners } from '@/app/libs/getData'
import { pages } from '@/app/utils/pages'

export default function HomePage({ bannerImage, bannerImageMobile, bannerImageTablet, setChatbotOpen, setIsConditionsModalOpen, setConditionsContent, bannerImages, bannerImagesMobile, bannerImagesTablet, calls, links, title, description }: HomeProps) {
  useUpdateHead(title, description)

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/byd/desktop/home/shark.webp"} media="(min-width: 1140px)" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/byd/tablet/home/shark.webp"} media="(min-width: 540px) and (max-width: 1139px)" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/byd/mobile/home/shark.webp"} media="(max-width: 539px)" />
        
        {bannerImage &&
          <>
            <link rel="preload" as="image" href={bannerImage} media="(min-width: 1140px)" />
            <link rel="preload" as="image" href={bannerImageTablet} media="(min-width: 540px) and (max-width: 1139px)" />
            <link rel="preload" as="image" href={bannerImageMobile} media="(max-width: 539px)" />
          </>
        }
      </Head>

      <Main
        bannerImages={bannerImages}
        bannerImagesMobile={bannerImagesMobile}
        bannerImagesTablet={bannerImagesTablet}
        calls={calls}
        links={links}
        setChatbotOpen={setChatbotOpen}
        setIsConditionsModalOpen={setIsConditionsModalOpen}
        setConditionsContent={setConditionsContent}
      />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const metadata: any = pages.find((page: any) => page.path === "/" && page.category === "root")
    const [banners] = await Promise.all([fetchBanners()])

    // Filtra as chamadas que contêm '/'
    const filteredIndexes = banners.calls
      .map((call: string, index: number) => call === '/' ? index : -1)
      .filter((index: number) => index !== -1)

    // Mapeia os arrays mantendo o tamanho original, incluindo entradas vazias
    const filteredContents = filteredIndexes.map(index => banners.contents[index] || null)
    const filteredDefenseTypes = filteredIndexes.map(index => banners.defenseTypes[index] || null)
    const filteredBannerImages = filteredIndexes.map(index => banners.bannerImages[index] || null)
    const filteredBannerImagesTablet = filteredIndexes.map(index => banners.bannerImagesTablet[index] || null)
    const filteredBannerImagesMobile = filteredIndexes.map(index => banners.bannerImagesMobile[index] || null)

    // Filtra os links apenas se a imagem do banner existir
    const filteredLinks = filteredIndexes.map(index => 
      (banners.bannerImages[index] ? banners.links[index] : null)
    ).filter(link => link !== null)

    const bannerImage = filteredBannerImages[filteredBannerImages.findIndex(item => item !== null)]
    const bannerImageTablet = filteredBannerImagesTablet[filteredBannerImagesTablet.findIndex(item => item !== null)]
    const bannerImageMobile = filteredBannerImagesMobile[filteredBannerImagesMobile.findIndex(item => item !== null)]

    return {
      props: {
        title: metadata.title,
        description: metadata.description,
        contents: filteredContents,
        defenseTypes: filteredDefenseTypes,
        bannerImages: filteredBannerImages,
        bannerImagesTablet: filteredBannerImagesTablet,
        bannerImagesMobile: filteredBannerImagesMobile,
        bannerImage,
        bannerImageTablet,
        bannerImageMobile,
        calls: banners.calls || [],
        links: filteredLinks || []
      }
    }

  } catch (error) {
    console.error('Erro ao buscar metadados ou banners:', error)
    return {
      props: {
        title: '',
        description: '',
        contents: [],
        defenseTypes: [],
        bannerImages: [],
        bannerImagesTablet: [],
        bannerImagesMobile: [],
        bannerImage: '',
        bannerImageTablet: '',
        bannerImageMobile: '',
        calls: [],
        links: []
      }
    }
  }
}
