import { useRouter } from 'next/router'
import Link from 'next/link'
import { ReactNode, AnchorHTMLAttributes, useMemo } from 'react'
import React from 'react'

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  children: ReactNode
}

const CustomLink: React.FC<CustomLinkProps> = ({ href, children, ...props }) => {
  const router = useRouter()

  const combinedHref = useMemo(() => {
    const [baseURL, hashFragment] = href.split('#')

    // Extrair os parâmetros de consulta atuais
    const currentParams = new URLSearchParams(router.query as Record<string, string>)

    // Remover o parâmetro choice se existir
    currentParams.delete('choice')
    currentParams.delete('pageName')

    // Extrair os parâmetros da href
    const [path, queryString] = baseURL.split('?')
    const newParams = new URLSearchParams(queryString || '')

    // Atualizar currentParams com newParams (isso substituirá os parâmetros existentes)
    newParams.forEach((value, key) => {
      currentParams.set(key, value)
    })

    // Construir a URL final com os parâmetros de consulta atualizados
    const queryStringFinal = currentParams.toString()
    let hrefWithParams = queryStringFinal ? `${path}?${queryStringFinal}` : path

    if (hashFragment) {
      hrefWithParams += `#${hashFragment}`
    }

    return hrefWithParams
  }, [href, router.query])

  return (
    <Link href={combinedHref} passHref {...props}>
      {children}
    </Link>
  )
}

export default React.memo(CustomLink)
