import { AppProps } from 'next/app'
import '@/styles/globals.scss'
import { useCookies } from '@/app/hooks/useCookies'
import Head from 'next/head'
import SetCookiesOnFirstVisit from '@/app/utils/SetCookiesOnFirstVisit'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { AuthProvider } from '@/app/contexts/AuthContext'
import Header from '@/app/components/UI/Header'
import Footer from '@/app/components/UI/Footer'
import HeaderCMS from '@/app/components/UI/HeaderCMS'
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@/app/contexts/ThemeContext'
import useGoogleTagManager from '@/app/hooks/useGoogleTagManager'
import CookieConsent from '@/app/components/UI/CookieConsent'
import FormSentModal from '@/app/components/UI/FormSentModal'
import Chatbot from '@/app/components/UI/Chatbot'
import ChatbotIcon from '@/app/components/UI/ChatbotIcon'
import { BannerProvider } from '@/app/contexts/BannerContext'
import { getFromIndexedDB, isUpdated } from '@/app/libs/getVehiclesBYD'
import useFetchAndCacheVehicles from '@/app/hooks/useFetchAndCacheVehicles'
import { BaseUrlImgProvider } from '@/app/contexts/BaseUrlImgContext'

const DynamicAlertModal = dynamic(() => import('@/app/components/UI/AlertModal'), { ssr: false })
const DynamicConditionsModal = dynamic(() => import('@/app/components/UI/ConditionsModal'), { ssr: false })

const cmsPath = '/cms'
const specialPaths = [
  '/programa-de-privacidade', '/checkin', '/checkout', '/folder',
  '/pesquisa', '/meu-byd', '/duvidas', '/contato-pos-venda', cmsPath, '/tv'
]
const chatPaths = [
  '/pos-venda'
]

const needsStandardLayout = (path: string): boolean => {
  return !specialPaths.some(specialPath => path === specialPath || path.includes(specialPath))
}

const needsStandardChatLayout = (path: string): boolean => {
  return !chatPaths.some(chatPaths => path === chatPaths || path.includes(chatPaths))
}

const App = ({ Component, pageProps }: AppProps & { dataStatus: { status: string | undefined, genStatus: string }}) => {
  const { cookiePreferences, handleConsent, hasCookie } = useCookies()
  const router = useRouter()
  const { asPath } = router
  const [newBanner, setNewBanner] = useState(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [isConditionsModalOpen, setIsConditionsModalOpen] = useState(false)
  const [conditionsContent, setConditionsContent] = useState<string>('')
  const [isFormSentModalOpen, setIsFormSentModalOpen] = useState(false)
  const [isChatbotOpen, setChatbotOpen] = useState(false)
  const [vehicles, setVehicles] = useState({ news: [], eletrics: [], hybrids: [] })
  const [isClient, setIsClient] = useState(false)

  const baseUrlImg = (() => {
    const currentUrl = router.basePath || typeof window !== "undefined" ? window.location.origin : ''
    if (currentUrl === 'https://byd-landingpage-prd.azurewebsites.net' || currentUrl === 'https://vitoriamotorsbyd.com.br') {
      return process.env.NEXT_PUBLIC_URL_IMG
    } else {
      return process.env.NEXT_PUBLIC_URL_IMG_HMG
    }
  })()

  const isFetchComplete = useFetchAndCacheVehicles(pageProps.dataStatus?.status, pageProps.dataStatus?.genStatus)

  useEffect(() => setIsClient(true), [])

  useEffect(() => {
    if (!isFetchComplete) return

    async function fetchAndSortVehicles() {
      const data = await getFromIndexedDB()
      const sortedVehicles = data.sort((a, b) =>
        String(a.family).localeCompare(String(b.family)) || a.order_family - b.order_family
      )

      const groupedVehicles = sortedVehicles.reduce(
        (acc: any, element: any) => {
          if (element.new) acc.news.push(element)
          if (element.model === 'eletric') acc.eletrics.push(element)
          if (element.model === 'hybrid') acc.hybrids.push(element)
          return acc
        },
        { news: [], eletrics: [], hybrids: [] }
      )

      setVehicles(groupedVehicles)
    }

    fetchAndSortVehicles()
  }, [isFetchComplete])

  const bannerData = {
    contents: pageProps.contents || [],
    defenseTypes: pageProps.defenseTypes || []
  }

  const handleAccept = useCallback(() => {
    handleConsent({ necessary: true, analytics: true, advertising: true, performance: true })
  }, [handleConsent])

  const handleDecline = useCallback(() => {
    handleConsent({ necessary: true, analytics: false, advertising: false, performance: false })
  }, [handleConsent])

  useGoogleTagManager()

  useEffect(() => {
    if (localStorage.getItem('showModalAfterReload') === 'true') {
      setIsFormSentModalOpen(true)
      setTimeout(() => {
        localStorage.removeItem('showModalAfterReload')
      }, 100)
    }
  }, [])

  return (
    <>
      <Head>
        <link rel="icon" href={'/img/favicon.ico'} />
      </Head>
      <BaseUrlImgProvider baseUrlImg={baseUrlImg!}>
        {needsStandardLayout(asPath) ? (
          <ThemeProvider>
            <BannerProvider {...bannerData}>
              <Header setIsAlertModalOpen={setIsAlertModalOpen} vehicles={vehicles} />
              <SetCookiesOnFirstVisit />
              <Component
                {...pageProps}
                cookiePreferences={cookiePreferences}
                setChatbotOpen={setChatbotOpen}
                setIsConditionsModalOpen={setIsConditionsModalOpen}
                setConditionsContent={setConditionsContent}
              />
              <CookieConsent
                onAccept={handleAccept}
                onDecline={handleDecline}
                buttonText="Aceitar todos os cookies"
                declineButtonText="Apenas os necessários"
                text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
                cookieURL='/cookies'
                hasCookie={hasCookie}
              />
              {isAlertModalOpen && <DynamicAlertModal setIsAlertModalOpen={setIsAlertModalOpen} />}
              {isConditionsModalOpen && <DynamicConditionsModal setIsConditionsModalOpen={setIsConditionsModalOpen} content={conditionsContent} />}
              {isFormSentModalOpen && <FormSentModal setIsFormSentModalOpen={setIsFormSentModalOpen} />}
              {needsStandardChatLayout(asPath) &&
                <>
                  <ChatbotIcon isChatbotOpen={isChatbotOpen} setChatbotOpen={setChatbotOpen} />
                  <Chatbot isOpen={isChatbotOpen} setChatbotOpen={setChatbotOpen} />
                </>
              }
              <Footer />
            </BannerProvider>
          </ThemeProvider>
        ) : asPath.includes(cmsPath) ? (
          <AuthProvider>
            {isClient && router.pathname !== cmsPath && window?.location?.hostname !== 'vitoriamotorsbyd.com.br' && (
              <HeaderCMS setNewBanner={setNewBanner} />
            )}
            <Component newBanner={newBanner} setNewBanner={setNewBanner} {...pageProps} />
          </AuthProvider>
        ) : (
          <ThemeProvider>
            <Component {...pageProps} cookiePreferences={cookiePreferences} />
            <CookieConsent
              onAccept={handleAccept}
              onDecline={handleDecline}
              buttonText="Aceitar todos os cookies"
              declineButtonText="Apenas os necessários"
              text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
              cookieURL='/cookies'
              hasCookie={hasCookie}
            />
          </ThemeProvider>
        )}
      </BaseUrlImgProvider>
    </>
  )
}

App.getInitialProps = async () => {
  const dataStatus = await isUpdated()
  return { pageProps: { dataStatus } }
}

export default App
